// @flow

class TelemetrySearchModel {
    _fullLevelId: ?string;
    _playerId: ?string;
    _applicationMajorVersion: ?number;
    _applicationMinorVersion: ?number;
    _applicationReleaseVersion: ?number;
    _applicationVersionExactMatch: ?boolean;
    _playerQuitLevel: ?boolean;
    _hasPreviouslyCompletedLevel: ?boolean;
    _playerLevelRatingMin: ?number;
    _playerLevelRatingMax: ?number;
    _groupByPlayer: ?boolean;
    _includeAdminEntries: ?boolean;
    _isPlayerRetentionRequest: ?boolean;



    static get Empty() : TelemetrySearchModel{
        return new TelemetrySearchModel(null, null, null, null, null, null, null, null, null, null, null, null, null);
    }

    constructor(fullLevelId: ?string,
                playerId: ?string,
                applicationMajorVersion: ?number,
                applicationMinorVersion: ?number,
                applicationReleaseVersion: ?number,
                applicationVersionExactMatch: ?boolean,
                playerQuitLevel: ?boolean,
                hasPreviouslyCompletedLevel: ?boolean,
                playerLevelRatingMin: ?number,
                playerLevelRatingMax: ?number,
                groupByPlayer: ?boolean,
                includeAdminEntries: ?boolean,
                isPlayerRetentionRequest: ?boolean,
    ) {
        this._fullLevelId = fullLevelId;
        this._playerId = playerId;
        this._applicationMajorVersion = applicationMajorVersion;
        this._applicationMinorVersion = applicationMinorVersion;
        this._applicationReleaseVersion = applicationReleaseVersion;
        this._applicationVersionExactMatch = applicationVersionExactMatch;
        this._playerQuitLevel= playerQuitLevel;
        this._hasPreviouslyCompletedLevel= hasPreviouslyCompletedLevel;
        this._playerLevelRatingMin = playerLevelRatingMin;
        this._playerLevelRatingMax = playerLevelRatingMax;
        this._groupByPlayer = groupByPlayer;
        this._includeAdminEntries = includeAdminEntries;
        this._isPlayerRetentionRequest = isPlayerRetentionRequest;

    }

    get fullLevelId(): ?string {
        return this._fullLevelId;
    }
    set fullLevelId(value: ?string) {
        this._fullLevelId = value;
    }

    get playerId(): ?string {
        return this._playerId;
    }
    set playerId(value: ?string) {
        this._playerId = value;
    }

    get applicationMajorVersion(): ?number {
        return this._applicationMajorVersion;
    }
    set applicationMajorVersion(value: ?number) {
        this._applicationMajorVersion = value;
    }

    get applicationMinorVersion(): ?number {
        return this._applicationMinorVersion;
    }
    set applicationMinorVersion(value: ?number) {
        this._applicationMinorVersion = value;
    }

    get applicationReleaseVersion(): ?number {
        return this._applicationReleaseVersion;
    }
    set applicationReleaseVersion(value: ?number) {
        this._applicationReleaseVersion = value;
    }

    get applicationVersionExactMatch(): ?boolean {
        return this._applicationVersionExactMatch;
    }
    set applicationVersionExactMatch(value: ?boolean) {
        this._applicationVersionExactMatch = value;
    }

    get playerQuitLevel(): ?boolean {
        return this._playerQuitLevel;
    }
    set playerQuitLevel(value: ?boolean) {
        this._playerQuitLevel = value;
    }

    get hasPreviouslyCompletedLevel(): ?boolean {
        return this._hasPreviouslyCompletedLevel;
    }
    set hasPreviouslyCompletedLevel(value: ?boolean) {
        this._hasPreviouslyCompletedLevel = value;
    }

    get playerLevelRatingMin(): ?number {
        return this._playerLevelRatingMin;
    }
    set playerLevelRatingMin(value: ?number) {
        this._playerLevelRatingMin = value;
    }

    get playerLevelRatingMax(): ?number {
        return this._playerLevelRatingMax;
    }
    set playerLevelRatingMax(value: ?number) {
        this._playerLevelRatingMax = value;
    }


    get groupByPlayer(): ?boolean {
        return this._groupByPlayer;
    }
    set groupByPlayer(value: ?boolean) {
        this._groupByPlayer = value;
    }

    get includeAdminEntries(): ?boolean {
        return this._includeAdminEntries;
    }
    set includeAdminEntries(value: ?boolean) {
        this._includeAdminEntries = value;
    }

    get isPlayerRetentionRequest(): ?boolean {
        return this._isPlayerRetentionRequest;
    }
    set isPlayerRetentionRequest(value: ?boolean) {
        this._isPlayerRetentionRequest = value;
    }
}


export default TelemetrySearchModel;
