// @flow

class TelemetryDataModel {
    _levelId: ?number;
    _fullLevelId: ?string;
    _playerName: ?string;
    _playerEmail: ?string;
    _playerId: ?string;
    _dataFileName: ?string;
    _playerQuitLevel: ?boolean;
    _playerSkippedLevel: ?boolean;
    _hasPreviouslyCompletedLevel: ?boolean;
    _playerLevelRating: ?number;
    _playerLevelRatingDescription: ?string;
    _applicationMajorVersion: ?number;
    _applicationMinorVersion: ?number;
    _applicationReleaseVersion: ?number;
    _applicationPatchVersion: ?number;
    _applicationVersionLabel: ?string;
    _date: ?Date;
    _timeSpentInLevel: ?number;
    _deathCount: ?number;
    _restartCount: ?number;
    _validationHash: ?string;
    _telemetryDataBinary: ?Object;
    _totalSecretsFound: ?number;
    _hintsUsed: ?number;
    _isInTimeTrialMode: ?boolean;
    _timeTrialBestTime: ?number;
    _isUsingGamepad: ?boolean;

    static get Empty(): TelemetryDataModel {
        return new TelemetryDataModel(null, null, null,null, null, null,  null, false, false, null, null, null, null, null, null, null, null, null, null, null, null, null, false, null, false, null, null);
    }

    constructor(levelId: ?number,
                fullLevelId: ?string,
                playerName: ?string,
                playerEmail: ?string,
                playerId: ?string,
                dataFileName: ?string,
                playerQuitLevel: boolean,
                playerSkippedLevel: boolean,
                hasPreviouslyCompletedLevel: boolean,
                playerLevelRating: ?number,
                playerLevelRatingDescription: ?string,
                applicationMajorVersion: ?number,
                applicationMinorVersion: ?number,
                applicationReleaseVersion: ?number,
                applicationPatchVersion: ?number,
                applicationVersionLabel: ?string,
                date: ?Date,
                timeSpentInLevel: ?number,
                totalSecretsFound: ?number,
                deathCount: ?number,
                restartCount: ?number,
                hintsUsed: ?number,
                isInTimeTrialMode: ?boolean,
                timeTrialBestTime: ?number,
                isUsingGamepad: ?boolean,
                validationHash: ?string,
                telemetryDataBinary: ?Object) {
        this._levelId = levelId;
        this._fullLevelId = fullLevelId;
        this._playerName = playerName;
        this._playerEmail = playerEmail;
        this._playerId = playerId;
        this._dataFileName = dataFileName;
        this._playerQuitLevel = playerQuitLevel;
        this._playerSkippedLevel = playerSkippedLevel;
        this._hasPreviouslyCompletedLevel = hasPreviouslyCompletedLevel;
        this._playerLevelRating = playerLevelRating;
        this._playerLevelRatingDescription = playerLevelRatingDescription;
        this._applicationMajorVersion = applicationMajorVersion;
        this._applicationMinorVersion = applicationMinorVersion;
        this._applicationReleaseVersion = applicationReleaseVersion;
        this._applicationPatchVersion = applicationPatchVersion;
        this._applicationVersionLabel = applicationVersionLabel;
        this._date = date;
        this._timeSpentInLevel = timeSpentInLevel;
        this._totalSecretsFound = totalSecretsFound;
        this._deathCount = deathCount;
        this._restartCount = restartCount;
        this._hintsUsed = hintsUsed;
        this._isInTimeTrialMode = isInTimeTrialMode;
        this._timeTrialBestTime = timeTrialBestTime;
        this._isUsingGamepad = isUsingGamepad;
        this._validationHash = validationHash;
        this._telemetryDataBinary = telemetryDataBinary;
    }

    get levelId(): ?number { return this._levelId; }
    set levelId(value: ?number) { this._levelId = value; }

    get fullLevelId(): ?string { return this._fullLevelId; }
    set fullLevelId(value: ?string) { this._fullLevelId = value; }

    get playerName(): ?string { return this._playerName; }
    set playerName(value: ?string) { this._playerName = value; }

    get playerEmail(): ?string { return this._playerEmail; }
    set playerEmail(value: ?string) { this._playerEmail = value; }

    get playerId(): ?string { return this._playerId; }
    set playerId(value: ?string) { this._playerId = value; }

    get dataFileName(): ?string { return this._dataFileName; }
    set dataFileName(value: ?string) { this._dataFileName = value; }

    get playerQuitLevel(): ?boolean { return this._playerQuitLevel; }
    set playerQuitLevel(value: ?boolean) { this._playerQuitLevel = value; }

    get playerSkippedLevel(): ?boolean { return this._playerSkippedLevel; }
    set playerSkippedLevel(value: ?boolean) { this._playerSkippedLevel = value; }

    get hasPreviouslyCompletedLevel(): ?boolean { return this._hasPreviouslyCompletedLevel; }
    set hasPreviouslyCompletedLevel(value: ?boolean) { this._hasPreviouslyCompletedLevel = value; }

    get playerLevelRating(): ?number { return this._playerLevelRating; }
    set playerLevelRating(value: ?number) { this._playerLevelRating = value; }

    get playerLevelRatingDescription(): ?string { return this._playerLevelRatingDescription; }
    set playerLevelRatingDescription(value: ?string) { this._playerLevelRatingDescription = value; }

    get applicationMajorVersion(): ?number { return this._applicationMajorVersion; }
    set applicationMajorVersion(value: ?number) { this._applicationMajorVersion = value; }

    get applicationMinorVersion(): ?number { return this._applicationMinorVersion; }
    set applicationMinorVersion(value: ?number) { this._applicationMinorVersion = value; }

    get applicationReleaseVersion(): ?number { return this._applicationReleaseVersion; }
    set applicationReleaseVersion(value: ?number) { this._applicationReleaseVersion = value; }

    get applicationPatchVersion(): ?number { return this._applicationPatchVersion; }
    set applicationPatchVersion(value: ?number) { this._applicationPatchVersion = value; }

    get applicationVersionLabel(): ?string { return this._applicationVersionLabel; }
    set applicationVersionLabel(value: ?string) { this._applicationVersionLabel = value; }

    get date(): ?Date { return this._date; }
    set date(value: ?Date) { this._date = value; }

    get timeSpentInLevel(): ?number { return this._timeSpentInLevel; }
    set timeSpentInLevel(value: ?number) { this._timeSpentInLevel = value; }

    get totalSecretsFound(): ?number { return this._totalSecretsFound; }
    set totalSecretsFound(value: ?number) { this._totalSecretsFound = value; }

    get deathCount(): ?number { return this._deathCount; }
    set deathCount(value: ?number) { this._deathCount = value; }

    get restartCount(): ?number { return this._restartCount; }
    set restartCount(value: ?number) { this._restartCount = value; }

    get hintsUsed(): ?number { return this._hintsUsed; }
    set hintsUsed(value: ?number) { this._hintsUsed = value; }

    get isInTimeTrialMode(): ?boolean { return this._isInTimeTrialMode; }
    set isInTimeTrialMode(value: ?boolean) { this._isInTimeTrialMode = value; }

    get timeTrialBestTime(): ?number { return this._timeTrialBestTime; }
    set timeTrialBestTime(value: ?number) { this._timeTrialBestTime = value; }

    get isUsingGamepad(): ?boolean { return this._isUsingGamepad; }
    set isUsingGamepad(value: ?boolean) { this._isUsingGamepad = value; }

    get validationHash(): ?string { return this._validationHash; }
    set validationHash(value: ?string) { this._validationHash = value; }

    get telemetryDataBinary(): ?Object { return this._telemetryDataBinary; }
    set telemetryDataBinary(value: ?Object) { this._telemetryDataBinary = value; }
}


export default TelemetryDataModel;
